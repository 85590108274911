<template>
  <div>
    <calendarBreadcrumbs :link="'dashboardPublish'" />
    <h3 class="flix-html-h3">{{ $t('message.embedCode', 1) }}</h3>
    <transition name="flixFadeIn">
      <div :key="updateKey">
        <calendarEmbed v-if="$route.params.id !== 'all'" :id="$route.params.id" />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    calendarBreadcrumbs () { return import('@/components/assets/calendarBreadcrumbs') },
    calendarEmbed () { return import('@/components/embed/menu') }
  },
  props: {},
  data () {
    return {
      updateKey: new Date().getTime()
    }
  },
  watch: {
    '$route.params.id' () { this.updateKey = new Date().getTime() }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
